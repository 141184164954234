import React from 'react';
import {useControllerProps} from '../../ControllerContext';
import {CommonProductLineItem} from './ProductLineItem';

export const ProductLineItems = () => {
  const {items, shouldUseLineItemPriceInTYP} = useControllerProps().thankYouPageStore;

  return (
    <div>
      {items.map((item, index: number) => {
        return (
          <CommonProductLineItem
            item={item}
            key={item.productId}
            withDivider={index !== items.length - 1}
            shouldUseLineItemPriceInTYP={shouldUseLineItemPriceInTYP}
          />
        );
      })}
    </div>
  );
};
